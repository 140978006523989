import React from 'react'

const EditedWorks = () => {
  return (
    <div className='p-5'>
<div className=''>
    <div className="relative text-center items-center justify-center">
      <img src={require(`./stroke 1.png`)} alt="example"className='w-80'  />
          <h1 className="absolute top-6 left-8 text-white font-bold text-xl ">EDITED WORKS</h1>
    </div>
    </div>
    <div className='max-w-screen-lg mx-auto text-justify mt-4 font-mal'>
    
    <div className=' text-2xl font-semibold text-tb mt-4 font-eng'>Edited Works - English</div>
        <div className='max-w-screen-lg mx-auto text-justify mt-4 '>
        1. A S P Ayyar, (KPK Menon), Macmillan India Ltd,Madras, 1980<br/>
2. Aubrey Menon (Mohammed Elias), Macmillan India Ltd., Madras, 1985<br/>
3. Best Loved Stories (With B Chandrika), Anuchitra, Madras, 1991<br/>
4. Basic Malayalam, Ayyappa Paniker, et.al, World Malayalee Council, Trivandrum, 1996<br/>
5. Contemporary Indian Fiction in English, University of Kerala, Trivandrum, 1986<br/>
6. Critical Essays: A Presentation, Macmillan Madras, 1987<br/>
7. Cygnus: Ji. of Research in English (Guest Editor), Centre for Common Wealth Literature, Lucknow, 1981<br/>
8. Dialogues - Six Literary Interviews, Post Graduate Department of English, Utkal University, 1959<br/>
9. English & India (With M Manuel), Macmillan, Madras, 1978<br/>
10. Encyclopedia of Indian Literature, Sahitya Akademi, New Delhi, 1987<br/>
11. High Planes Literary Review (Advisory Editor), Colorado 1988<br/>
12. Indian English Literature Since Independence, Indian Association of English Studies, New Delhi, 1991<br/>
13. Indian Journal of English Studies, Indian Association of English Studies, New Delhi, 1981<br/>
14. Indian Poetry Today 4 (Malayalam Section) Indian Council for Cultural Relation, New Delhi, 1981<br/>
15. Indian Renaissance, Sterling Publishers, New Delhi, 1983<br/>
16. Journal of South Asian Literature (Associate Editor), Michigan State University, 1980<br/>
17. Kathakali: The Art of The Non-Worldly (With D Appukuttan Nair), Marg, Bombay, 1993<br/>
18. Kamala Das (K Radha), Macmillan India Ltd., Madras, 1986<br/>
19. KPS Menon (N Viswanathan), Macmillan India Ltd., Madras, 1983<br/>
20. Kerala Contribution to Indian Writing in English (With N Viswanathan, An Annotated Bibliography), Dept. of Publications, University of Kerala, 1998<br/>
21. Kerala University Youth 5, University of Kerala, 1966<br/>
22. Kumaranasan: The Man and The Poet, Kumaranasan Memorial Committee, Thonnakkal, 1988<br/>
23. Literary Studies (With KPK Menon & M Manuel), Dr. A Sivarama Subrahmanya Aiyer Memo- rial Committee, Thiruvananthapuram, 1973<br/>
24. Literary Translation, Kendra sahithya Academy New Delhi, 1989<br/>
25. Magic Circle of Henry James (With Amrijith Singh, Envoy Press, New York, 1989<br/>
26. Making of Indian Literature, (A Consolidated Re- port of Workshops on Literary Translation 1986-88), Sahitya Akademi, New Delhi, 1991<br/>
27. Malayalam Short Stories: An Anthology, Vikas New Delhi 1981<br/>
28. Medieval Indian Literature, Sahitya Academy, New Delhi, 1997<br/>
29. Menon Marath (Mohammed Elias), Macmillan India Ltd., Madras, 1981<br/>
30. Modern Indian Poetry in English, Sahitya Academy, New Delhi, 1991<br/>
31. New Frontier: The Hero of Post-War Fiction, University of Kerala, 1992<br/>
32. P Palpu (N Viswanathan), Macmillan India Ltd., 1981<br/>
33. Particulars & Universals (With Elias Valentine), Emerald, Madras, 1986<br/>
34. Poet's Eye, S Chand, New Delhi, 1977<br/>
35. Pothen Joseph (N Viswanathan), Macmillan India Ltd., Madras, 1988<br/>
36. Pride of Poets, Oxford University Press, Madras, 1979<br/>
37. Robert Lowell Society Papers, Macmillan, Madras, 1984<br/>
38. Sangeet Natak, Sangeet Natak Kudiyattam Special Issue 111-114 Sangeet Natak Akademi, New Delhi<br/>
39. Studies in Comparative Literature (With Bernard Fenn), Blackie & Son, Bombay, 1985<br/>
40. The 20th Century Malayalam Literature, Nerc Thiruvananthapuram & Vidyarthimitram Kottayam, 2000<br/>
41. Towards an Alternative AESTHETICS<br/>
42. University Youth Welfare (With E.I. George), University of Kerala, 1966<br/>
43. V K Krishna Menon; Kerala Writers in English 10, (With B. Chandrika) Macmillan, New Delhi, 1990<br/>
44. Vallathol: A Centenary Perspective (Co-Editor), Vallathol Centenary Committee, Thiruvananthapuram, 1978<br/>
45. Journal of Literature and Aesthetics<br/>
46. George Orwell and Nineteen Eighty Four<br/>
            </div>
    <div className=' text-2xl font-semibold text-tb mt-4 font-eng'>Edited Works - Malayalam</div>
        <div className='max-w-screen-lg mx-auto text-justify mt-4'>
        1. Vishwa Sahitya Mala, DC Book Kottayam 1982-1987<br/>
2. Tagorum Keralavum, Kerala Bhasha Institute Trivandrum1987<br/>
3. Loka Katha, Sahithyapravarthaka Sahakarana Sangham Kottayam 1991, DC Books Kottayam 2000<br/>
4. Nooru Varsham Nooru Katha (co-edited), DC Books Kottayam 1991<br/>
5. Madhyakala Malayala Kavita, National Book Trust New Delhi 1998<br/>
6. D C Kaalathinte Karmasakshi, DC Books Kottayam 1999<br/>
7. Gaveshana Pratibha, DC Books Kottayam 1999<br/>
8. Shakespeare: Sampoorna Kritikal -3 Vols, DC Books Kottayam 2000<br/>
9. Natakachakram, DC Books 2005<br/>
10. English English Malayalam Nighandu (T Ramalingam Pillai) DC Books Kottayam 2002<br/>
11. Malayalam Thisoras (Col.N B Nair) DC Books Kottayam 2002<br/>
12. 100 Varsham Kavitha irupatham Nootandile Malayala Kavitha<br/>
            </div>
    
    </div>

    </div>
  )
}

export default EditedWorks