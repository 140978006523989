import React from 'react'

const Vivarthakan = () => {
  return (
    <div className='p-5'>
        
<div className=''>
    <div className="relative text-center items-center justify-center">
      <img src={require(`./stroke 1.png`)} alt="example"className='w-80'  />
          <h1 className="absolute top-5 left-10 text-white font-bold text-2xl ">വിവർത്തകൻ</h1>
    </div>
    </div>
       
    <div className='max-w-screen-lg mx-auto text-justify mt-4 font-mal'>
    ലോക സാഹിത്യത്തിനു ഒപ്പം നിൽക്കുന്ന രീതിയിൽ മലയാളത്തിന്റെ കാവ്യബോധത്തിലും സംസ്കാരത്തിലും മാറ്റങ്ങൾ കൊണ്ടുവരുവാൻ 
    അയ്യപ്പപ്പണിക്കരുടെ ഇംഗ്ലീഷ് പാണ്ഡിത്യം സഹായകമായിട്ടുണ്ട്. എലിയട്ട്, ബോദ്ലയെർ, ബ്രഹത്, നെരൂദ, ഹോചിമിൻ, നിക്കോളാസ് ഗിയൻ, 
    മയ്ക്കോ വിസ്കി, യെവത്ഷെങ്കോ, ജീൻ ടൂമർ തുടങ്ങി അനേകം എണ്ണം പറഞ്ഞ എഴുത്തുകാരെ വിവർത്തനം ചെയ്ത് പണിക്കർ മലയാളികൾക്ക് 
    പരിചിതരാക്കി. ആഫ്രിക്കൻ, ലാറ്റിനമേരിക്കൻ, യൂറോപ്യൻ, ഇംഗ്ലീഷ് എഴുത്തുകാരുടെ മഹത്തായ കൃതികൾ കടമ്മനിട്ട, സച്ചിദാനന്ദൻ, 
    കെ.ജി. ശങ്കരപ്പിള്ള, ഡി. വിനയചന്ദ്രൻ തുടങ്ങിയവരെക്കൊണ്ട് അദ്ദേഹം തർജ്ജമ ചെയ്യിച്ചു. രാജാറാവുവിന്റെ 'പൂച്ചയും ഷേക്സ്പിസിയറും'
    അടക്കം പല ഗ്രന്ഥങ്ങളും അദ്ദേഹം മലയാളത്തിലാക്കിയിട്ടുണ്ട്.<br/><br/>

    വിശ്വസാഹിത്യത്തെ കേരളത്തിലേക്ക് വരവേൽക്കുക മാത്രമല്ല മലയാളകവികളെ 
    മറ്റ് ഇന്ത്യൻ ഭാഷകളിലേക്കും ഇംഗ്ലീഷിലേക്കും വിവർത്തനം ചെയ്ത്, അയ്യപ്പപ്പണിക്കർ 
    മലയാളത്തിന് മറുകരയിലേക്കുള്ള പാലം പണിയുന്നതിന് മുൻകൈ എടുത്തു. ഇംഗ്ലീഷിൽ 
    കവിതകൾ എഴുതിയിട്ടില്ലെങ്കിലും സ്വന്തം കവിതകൾ ഇംഗ്ലീഷിലേക്കു പരിഭാഷപ്പെടുത്തിയിട്ടുണ്ട്.
    <div className=' text-xl font-semibold text-tb mt-4'>കവിത</div>
        <div className='max-w-screen-lg mx-auto text-justify mt-4 font-mal'>
            1. നിക്കൊളാസ് ഗിയൻ ക്യൂബൻ കവിതകൾ, ശിഖ പബ്ലിക്കേഷൻസ്,ഗുരുവായൂർ, 1984<br/><br/>
            2. മയക്കോവ്സ്കിയുടെ കവിതകൾ, സാഹിത്യപ്രവർത്തക സഹകരണസംഘം, കോട്ടയം, 1988<br/><br/>
            3. ജീബനാനന്ദദാസിന്റെ കവിതകൾ, ഡി സി ബുക്സ്, കോട്ടയം, 2000<br/><br/>
            4. നൂറ്റൊന്നുവചനങ്ങൾ, എച്ച് & സി പബ്ലിഷിംഗ് ഹൗസ്, തൃശൂർ, 2009<br/><br/>
            5. കവിത പ്രണയം ആത്മകഥ-മയക്കോവ്സ്കി, പാപ്പിയോൺ, കോഴിക്കോട്, 2005
            </div>
    <div className=' text-xl font-semibold text-tb mt-4'>ഗദ്യം</div>
        <div className='max-w-screen-lg mx-auto text-justify mt-4 font-mal'>
              6. പൂച്ചയും ഷേക്സ്പിയറും, ഡി സി ബുക്സ്, കോട്ടയം, 1980<br/><br/>
              7. ജീബനാനന്ദദാസ്, സാഹിത്യ അക്കാദമി, ന്യൂഡൽഹി, 1985<br/><br/>
              8. ഗുരുഗ്രന്ഥസാഹിബ്, ഡി സി ബുക്സ്, കോട്ടയം, 1987<br/><br/>
              9. അയ്യപ്പപ്പണിക്കരുടെ വിവർത്തനങ്ങൾ, പൂർണ പബ്ലിക്കേഷൻസ്, കോഴിക്കോട്, 1990<br/><br/>
              10. ഗുരുനാനക്, സാഹിത്യ അക്കാദമി, ന്യൂഡൽഹി, 1991
            </div>
    </div>

    







    </div>






  )
}

export default Vivarthakan
