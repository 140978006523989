import React from 'react'

const EditorSamaaharakarthavu = () => {
  return (
    <div className='p-5'>
 <div className=''>
    <div className="relative text-center items-center justify-center">
      <img src={require(`./stroke 1.png`)} alt="example"className='w-80'  />
          <h1 className="absolute top-7 left-4 text-white font-bold text-[14px] ">എഡിറ്റർ സമാഹാരകർത്താവ്</h1>
    </div>
    </div>
    <div className='max-w-screen-lg mx-auto text-justify mt-4 font-mal'>
    എഡിറ്റർ എന്ന നിലയിൽ ഇംഗ്ലീഷിലും മലയാളത്തിലും ഒരു പോലെ പ്രഗത്ഭനായ 
    അപൂർവ പ്രതിഭ ആയിരുന്നു അയ്യപ്പപ്പണിക്കർ, ഭാഷയിലുള്ള പ്രാവീണ്യം, 
    ഉള്ളടക്കത്തെക്കുറിച്ചുള്ള ആഴത്തിലുള്ള അറിവ്, വിശദാംശങ്ങളിൽ ശ്രദ്ധപതിപ്പിച്ചുള്ള 
    സൂക്ഷ്മമായ വായനാപാടവം, എഴുത്തുകാരുമായുള്ള തന്മയീഭാവം തുടങ്ങി ഒരു എഡിറ്റർക്ക് 
    ഉണ്ടായിരിക്കേണ്ട ഗുണവിശേഷങ്ങൾ എല്ലാം തന്നെ അവയുടെ പരിപൂർണ്ണതയിൽ അയ്യപ്പപ്പണിക്കർ 
    എന്ന എഡിറ്ററിൽ കാണാനാവും. മാക്മിലൻ പ്രസിദ്ധീകരിച്ച "കേരള റൈറ്റേഴ്സ് ഇൻ ഇംഗ്ലീഷ്' 
    എന്ന പരമ്പരയുടെ ജനറൽ എഡിറ്റർ ആയും മിഷിഗൺ സർവകലാശാല പ്രസിദ്ധപ്പെടുത്തുന്ന 
    "ജേർണൽ ഓഫ് സൗത്ത് ഏഷ്യൻ ലിറ്ററേച്ചറി'ന്റെ അസ്സോസിയേറ്റ് എഡിറ്റർ ആയും പണിക്കർ പ്രവർത്തിച്ചിട്ടുണ്ട്. 
    എൻസൈക്ലോപീഡിയ ബ്രിട്ടാനിക്ക അടക്കം പല പ്രസാധക സംഘങ്ങൾക്കും അദ്ദേഹം ലേഖനങ്ങൾ എഡിറ്റ് 
    ചെയ്തു കൊടുത്തിരുന്നു. കേന്ദ്ര സാഹിത്യ അക്കാദമി പ്രസിദ്ധീകരിച്ച "മധ്യകാല മലയാളസാഹിത്യം' 
    'ഇന്ത്യൻ സാഹിത്യവിജ്ഞാനകോശം' എന്നിവയുടെ എഡിറ്റർ ആയി പണിക്കർ ഏറെക്കാലം പ്രവർത്തിച്ചു. 
    'വിശ്വസാഹിത്യമാല' എന്ന പേരിൽ ഡി.സി. ബുക്സ് പുറത്തിറക്കിയ, വിശ്വസാഹിത്യത്തിലെ നൂറ്റിഇരുപതു 
    പ്രധാനപ്പെട്ട പുസ്തകങ്ങൾ എഡിറ്റ് ചെയ്തത് അയ്യപ്പപ്പണിക്കരായിരുന്നു. ഡി.സി.യുടെ തന്നെ "ഷേക്സ്പിയർ സമ്പൂർണ 
    കൃതികൾ' (മൂന്നു വാല്യങ്ങൾ) എഡിറ്റ് ചെയ്തതും അദ്ദേഹമായിരുന്നു. ആയിരത്തിൽപ്പരം പേജുകളുള്ള മൂന്നു വാള്യങ്ങൾ 
    വരുന്ന ബ്രഹത്ഗ്രന്ഥപരമ്പരയാണിത്. പൊതുവായ മുഖവുരയ്ക്ക് പുറമെ ഓരോ നാടകത്തിനും പ്രത്യേകമായി ലഘു ആമുഖവും 
    അദ്ദേഹം നൽകിയിട്ടുണ്ട്.<br/><br/><br/>

    <div className=' text-xl font-semibold text-tb mt-4'>അയ്യപ്പപ്പണിക്കർ എഡിറ്റ് ചെയ്ത മലയാള ഗ്രന്ഥങ്ങൾ</div>
        <div className='max-w-screen-lg mx-auto text-justify mt-4'>
             1. വിശ്വസാഹിത്യമാല (120 കൃതികൾ 128 പുസ്തകങ്ങൾ), ഡി.സി. ബുക്സ്, കോട്ടയം, 1982-87<br/><br/>
             2. ടാഗോറും കേരളവും, കേരള ഭാഷാ ഇൻസ്റ്റിറ്റ്യൂട്ട്, തിരുവനന്തപുരം, 1987<br/><br/>
             3. ലോകകഥ (കഥകൾ), സാഹിത്യപ്രവർത്തക സഹകരണസംഘം, കോട്ടയം, 1991<br/><br/>
             4. ലോകകഥ (കഥകൾ) ഡി.സി. ബുക് സ്, കോട്ടയം, 2000<br/><br/>
             5. 100 വർഷം 100 കഥ, ഡി.സി. ബുക്സ്, കോട്ടയം, 1991<br/><br/>
             6. മദ്ധ്യകാല മലയാളകവിത, നാഷണൽ ബുക് ട്രസ്റ്റ്, ന്യൂഡെൽഹി, 1998<br/><br/>
             7. ഡി.സി. കാലത്തിന്റെ കർമ്മസാക്ഷി, ഡി.സി.ബുക്സ്, കോട്ടയം, 1999<br/><br/>
             8. ഗവേഷണപ്രതിഭ, ഡി.സി. ബുക്സ് കോട്ടയം, 1999<br/><br/>
             9. ഷേക്സ്പിയർ സമ്പൂർണ കൃതികൾ (3 വാല്യം), ഡി.സി. ബുക്സ്, കോട്ടയം, 2000<br/><br/>
             10. നാടകചക്രം (11 നാടകങ്ങൾ), ഡി.സി. ബുക്സ്, 2005<br/><br/>
             11. 100 വർഷം കവിത, ഇരുപതാം നൂറ്റാണ്ടിലെ മലയാള കവിത<br/><br/>
             12. ഇംഗ്ലീഷ്-ഇംഗ്ലീഷ്-മലയാളം നിഘണ്ടു, (ടി. രാമലിംഗംപിള്ള), ഡി.സി. ബുക്സ്, 2002<br/><br/>
             13. മലയാളം തിസോറസ്-പദാന്വേഷണ ശബ്ദകോശം (കേണൽ എൻ.ബി. നായർ), ഡി.സി. ബുക്സ്, കോട്ടയം, 2002<br/><br/>
            </div>
    
    </div>

    </div>
  )
}

export default EditorSamaaharakarthavu