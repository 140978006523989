import React from 'react'

const MalayalamKavi = () => {
  return (
    <div className='p-5'>
    <div className=''>
    <div className="relative text-center items-center justify-center">
      <img src={require(`./stroke 1.png`)} alt="example" className='w-80'  />
          <h1 className="absolute top-4 left-20 text-white font-bold text-2xl  ">കവി</h1>
    </div>
    </div>
    <div className='max-w-screen-lg mx-auto text-justify mt-4 font-mal '>മലയാളകവികളിൽ കുമാരനാശാനെയും, അയ്യപ്പപ്പണിക്കരെയെയും കുറിച്ചാണ് 
    ഏറ്റവും കൂടുതൽ ഗവേഷണ പ്രബന്ധങ്ങൾ ഉണ്ടായിട്ടുള്ളത് എന്ന് പറയപ്പെടുന്നു. എന്നിരിക്കിലും അയ്യപ്പപ്പണിക്കരുടെ കാവ്യപ്രപഞ്ചം 
    വൈവിധ്യംകൊണ്ടും, വൈചിത്രംകൊണ്ടും, വൈരുധ്യംകൊണ്ടും അനുവാചകനെ ഇന്നും വിസ്മയിപ്പിക്കുന്നു. കാല്പനിക വരികൾ 
    എഴുതിയിട്ടുള്ള അകാല്പനികനും, ദണ്ഡകങ്ങൾ എഴുതിയിട്ടുള്ള വൃത്തനിഷേധിയും ആയിരുന്നു അദ്ദേഹം.<br/><br/>

    അരനൂറ്റാണ്ടിലേറെയുള്ള പണിക്കരുടെ കാവ്യസപര്യയിൽ അദ്ദേഹം എഴുതിയ എഴുന്നൂറിലേറെയുള്ള കവിതളോരോന്നും 
    പുതുക്കിപ്പണിയലിന്റെ രേഖാചിത്രങ്ങളാണ്. രൂപത്തിലും പ്രമേയത്തിലും പ്രതിപാദനത്തിലും ദർശനത്തിലുമുള്ള നവീകരണംകൊണ്ട് 
    കാല്പനികതയിൽനിന്ന് ആധുനികതയിലേയ്ക്കും ആധുനികതയിൽ നിന്നു ഉത്തരാധുനികതയിലേയ്ക്കും അനായാസമായി ഒരു സഞ്ചാരപഥം 
    തെളിക്കുവാൻ അദ്ദേഹത്തിന് സാധിച്ചു. ഒരു ഭാവുകത്വത്തെയും സാഹിത്യപ്രസ്ഥാനത്തേയും തള്ളിക്കളയുകയല്ല, അവമറികടന്നുപോവുകയാണ് 
    അദ്ദേഹം ചെയ്തത്. 1946-ൽ കവിതയെഴുതാൻ തുടങ്ങിയ അയ്യപ്പപ്പണിക്കർ കാല്പനികതാഭാവം മുറ്റി നിൽക്കുന്ന കവിതകൾ രചിച്ചിരുന്നുവെങ്കിലും 
    കാല്പനികതയുടെ കപട ഗൗരവത്തെ നർമ്മം കൊണ്ട് മുറിവേൽപ്പിക്കുകയും അതിനോട് കലഹിക്കുകയും ചെയ്തു. അതുവരെ പ്രചാരത്തിലുള്ളതും 
    സമ്മതിയാർജിച്ചതുമായ കാവ്യാനുശീലനങ്ങളിൽ നിന്ന് വേർപ്പെട്ട്, നവീനമായ ഒരു ആധുനിക ശൈലിയിൽ മനുഷ്യക്കഥ പറയുവാനുള്ള ശ്രമമാണ് 
    പണിക്കർ കവിതകൾ.<br/> <br/>      

    രൂപത്തിലും, പ്രമേയത്തിലും, പ്രതിപാദനത്തിലും മലയാളകവിതയെ നവീകരിച്ചു എന്നുള്ളതാണ് അയ്യപ്പപ്പണിക്കർക്ക് നമ്മുടെ കവിതയിലുള്ള 
    അനിഷേധ്യമായ സ്ഥാനം. ആധുനികപൂർവ്വകാലം, ആധുനികതയുടെ പ്രഭാവകാലം, ഉത്തരാധുനികതയുടെ കാലം- ഇങ്ങനെ ഓരോ കാലത്തെയും 
    ഭാവുക ത്വസംക്രമണങ്ങളിൽ ഒളിമങ്ങാതെ നേതൃനിരയിൽ കവിതയെ സമസ്ത മേഖലകളിലും നവീകരിച്ചുകൊണ്ടു പണിക്കർ സജീവമായി 
    നിലകൊണ്ടു.<br/><br/>
  
    സാമ്പ്രദായിക വൃത്തനിബന്ധനകൾ കവിതയെഴുതുന്നതിനു നിർബന്ധമാക്കരുതെന്നു വാദിച്ചപ്പോഴും, സ്വന്തമായി ഒരു താളം സൃഷ്ടിക്കാൻ 
    കഴിവുള്ളവർക്കേ കവിയാകാനാവു എന്നദ്ദേഹം എലിയട്ടിനെ ഉദ്ധരിച്ചെഴുതിയിട്ടുണ്ട്. വൃത്തം ഉപയോഗിച്ചും ഉപയോഗിക്കാതെയും 
    കവിതാരചനയിൽ മികവ് പുലർത്തി എന്നുള്ളതാണ് അയ്യപ്പപ്പണിക്കരുടെ പ്രത്യേകത. (പിന്നീട് വന്ന കടമ്മനിട്ടയും സച്ചിദാനന്ദനും 
    ഈ കാവ്യരീതിയെ ധന്യമാക്കി). മൃത്യപൂജ, കുടുംബപുരാണം, കൊതുകിൽതോറ്റം, നാടെവിടെ മക്കളെ, ഗോപികദണ്ഡകം, ഗോത്രയാനം, 
    സൂരജ്മുഖി, പൂക്കാതിരിക്കാനെനിക്കാവതില്ലേ തുടങ്ങിയനേകം മികച്ച കവിതകൾ ഈണത്തിൽ ചൊല്ലാവുന്ന വൃത്തത്തിലുള്ള കൃതികളാണ്. 
    അതേസമയം മഹാരാജകഥകൾ, ലെനിൻ പഠിപ്പിച്ച ഒൻപതു പാഠങ്ങൾ, ഗാന്ധിജി പഠിച്ച മൂന്നു പാഠങ്ങൾ, ഹൂഗ്ലി, ലെബനൻ, ശത്രുഭയം, 
    വീഡിയോ മരണം തുടങ്ങിയനേകം കവിതകൾ ഗദ്യത്തിലാണെന്നു മാത്രമല്ല, ചിലതു കഥകൾ പോലെയാണുതാനും. കഥയുടെയും 
    കവിതയുടെയും അതിർവരമ്പുകൾ അപ്രത്യക്ഷമാവുന്നത് ആധുനികതക്ക് എഴുപതുകളിൽ പുതിയ മാനം നൽകിയ കെ.ജി.എസ്സിനെ 
    പോലുള്ള കവികൾ സ്വാഗതം ചെയ്തെങ്കിലും പാരമ്പര്യവാദികൾ നിശിതമായി വിമർശിച്ചു. എതിർപ്പ് അംഗീകാരമാണെന്നും അതുകൊണ്ടു 
    ദേഷ്യത്തിന് പകരം നന്ദിയാണ് നമുക്കുണ്ടാകേണ്ടതെന്നും പണിക്കർ പഠിപ്പിച്ചു.<br/><br/>


    പുതു രചനാരീതികളിലൂടെ കാല്പനിക കാലത്തെ പ്രമേയങ്ങൾ ആധുനിക കാഴ്ചപ്പാടോടെ അദ്ദേഹം അവതരിപ്പിച്ചു. പകലുകൾ രാത്രികൾ, 
    ഗോപികാദണ്ഡകം എന്നി കവിതകളിലെ പ്രണയവും വിരഹവും കാല്പനികതയിൽ നിന്നു ആധുനികതയിലേക്കുള്ള ഭാവസംക്രമണത്തെ 
    വ്യക്തമാക്കുന്നു. പ്രണയം തീവ്രമാകുന്നത് വിരഹാംശം കൂടുമ്പോഴാണ്. കാല്പനികതയിൽ സഫലമാകാത്ത പ്രണയം വിഷാദത്തിലേക്കും 
    വിഷാദം ജീവിതനിരാസത്തിലേക്കും കൂപ്പുകുത്തുന്നു. പണിക്കർക്കവിതകളിൽ കാല്പനിക നിശ്വാസങ്ങളില്ല, നെടുവീർപ്പുകളില്ല.<br/><br/>

    അയ്യപ്പപ്പണിക്കരുടെ കവിതകൾ വിപരീതങ്ങളും വൈരുധ്യങ്ങളുംകൊണ്ട് സമൃദ്ധമാണ്. എത്രയോ കവിതകൾക്ക് വിപരീതങ്ങൾ 
    ചേർത്ത തലക്കെട്ടുകൾ അദ്ദേഹം നൽകിയിട്ടുണ്ട് ! നീ ഞാൻ ശരിയും തെറ്റും, ഉദയാസ്തമനം, പകലുകൾ രാത്രികൾ, തെക്കും വടക്കും, 
    ശത്രുമിത്രം, ഗ്രാമനഗരം, സംഹാര സൃഷ്ടി, പുത്തൻവീട്ടിൽ മുത്തി,- ഇതുപോലെ ധാരാളം കവിതകൾ ! മൃത്യുപൂജ എഴുതിയ ആൾതന്നെ 
    മർത്യപൂജ എഴുതി. ശാപവും ശാപമോചനവും, ശത്രുമിത്രവും ശത്രുഭയവും, ഗ്രാമനഗരവും നഗരഗ്രാമവും, ഇങ്ങനെ വിപരീതങ്ങളെയും 
    വൈരുധ്യങ്ങളെയും ഇത്രയധികം ആഴത്തിൽ മുങ്ങി ആശ്ലേഷിച്ച് മറ്റൊരു കവി നമുക്കില്ല. മനുഷ്യാവസ്ഥയിലെ വിപരീതങ്ങളുടെ 
    ഇണങ്ങിച്ചേരലാണ്, പാരസ്പര്യമാണ് അയ്യപ്പപ്പണിക്കരുടെ കൃതികളുടെ സവിശേഷത. പണിക്കരുടെ ട്രേഡ് മാർക്കായി മാറിയ ഐറണിയും 
    നർമവും ഈ വിപരീതങ്ങളെ അവതരിപ്പിക്കാൻ ഒരു നവീന രചനാരീതിയായത് അങ്ങനെയായിരിക്കാം. താൻ ജീവിക്കുന്ന കാലത്തിന്റെ 
    പറഞ്ഞറിയിക്കാനാവാത്ത ദുരന്തം പണിക്കരെ പോലെ ലളിതമായി അവതരിപ്പിച്ച കവികൾ കുറവാണ്. കാലിത്തീറ്റ, അന്ത്യം, പ്രതിജ്ഞ 
    തുടങ്ങി അനേകം കവിതകൾ നാടകീയ വിരുദ്ധോക്തിയുടെ ഉദാഹരണങ്ങളായി ചൂണ്ടിക്കാട്ടാം.<br/><br/>

    കാല്പനിക കവിതയുടെ സൗരഭ്യത്തിലും സൗന്ദര്യത്തിലും ലയിച്ച്, സുഖാലസ്യത്തിലാണ്ടു പോയ വായനക്കാർ ഐറണിയുടെ മിന്നൽ 
    പിണരുകളേറ്റ് ഞെട്ടിയുണർന്നപ്പോൾ അവരെ പുതിയ കാവ്യ ഭാവുകത്വത്തിലേക്ക് അയ്യപ്പപ്പണിക്കർ ആനയിച്ചു. അതിജീവനത്തിന്റെ 
    ദർശനം ഹാസ്യത്തിന്റെ എല്ലാ രുചിഭേദങ്ങളും - നിർദ്ദോഷമായ ശുദ്ധനർമം മുതൽ തൊലിയുരിയുന്ന ആക്ഷേപഹാസ്യം വരെ - 
    അയ്യപ്പപ്പണിക്കരുടെ കൃതികളിൽ കാണാം, അതുപോലെതന്നെ വിരുദ്ധോക്തിയുടെ എല്ലാ സൗമ്യ-രൗദ്ര ഭാവങ്ങളും. പക്ഷേ ഏതു 
    നർമ്മത്തിലും, ഹാസ്യത്തിലും, വിരുദ്ധഹാസ്യത്തിലും വിഷാദത്തിന്റെ നിഴൽ പരന്നു കിടക്കുന്നതു ഗൗരവമുള്ള വായനക്കാർ തിരിച്ചറിയുന്നു. 
    അദ്ദേഹത്തിന്റെ കൃതികളുടെ അന്തസത്തയിലേക്കുള്ള അന്വേഷണം ആരംഭിക്കുന്നത് ഈ മുനമ്പിൽ നിന്നാണ്.<br/> <br/>

    മഹാദുഃഖങ്ങളെ മാറോടണക്കുമ്പോഴുണ്ടാകുന്ന വ്യഥയും സുഖവും; തന്റെ കൃതികളെ പ്രചോ ദിപ്പിച്ചിട്ടുണ്ടെന്നു അദ്ദേഹം തന്നെ എഴുതിയിട്ടുണ്ട്. 
    ആകുലതകളും വ്യാകുലതകളും നിറഞ്ഞ മനുഷ്യാനുഭവങ്ങളുടെ തീച്ചൂളയിൽ നിന്ന് ഒരിക്കലും കാലഹരണപ്പെടാത്ത ഒരു ജീവിതദർശനം 
    അദ്ദേഹം ഊതിക്കാച്ചിയെടുത്തു. വ്യഥയിൽ നിന്നു വീര്യം കൊണ്ടുവരുന്നൊരു താളമായി താരമായി, മലയാള കാവ്യചരിത്രത്തിൽ അയ്യപ്പപ്പണിക്കർ 
    എന്നും നിലനിൽക്കുന്നു<br/><br/>

1. അയ്യപ്പപ്പണിക്കരുടെ കവിതകൾ - ഭാഗം 1, ഡി സി ബുക്സ്, കോട്ടയം<br/><br/>

2. അയ്യപ്പപ്പണിക്കരുടെ കവിതകൾ - ഭാഗം 2, ഡി സി ബുക്സ്, കോട്ടയം</div>
    </div>
  )
}

export default MalayalamKavi