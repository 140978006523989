import React from 'react'

const Niroopakan = () => {
  return (
    <div className='p-5'>
        <div className=''>
    <div className="relative text-center items-center justify-center">
      <img src={require(`./stroke 1.png`)} alt="example"className='w-80'  />
          <h1 className="absolute top-5 left-10 text-white font-bold text-2xl ">നിരൂപകൻ</h1>
    </div>
    </div>
    <div className='max-w-screen-lg mx-auto text-justify mt-4 font-mal'>
    അയ്യപ്പപ്പണിക്കരുടെ 'ഇന്ത്യൻ സാഹിത്യ സിദ്ധാന്തം - പ്രസക്തിയും സാധ്യതയും' എന്ന പുസ്തകം പ്രാചീന 
    ഭാരതീയ കാവ്യമീമാംസയുടെ ഇന്നത്തെ പ്രസക്തി കണ്ടെത്താനുള്ള ശ്രമമാണ്. സിദ്ധാന്തങ്ങൾ 
    പരിണമിച്ചുകൊണ്ടിരിക്കുകയാണെന്നും, ഇന്നത്തെ അവസ്ഥയിൽ ആ സിദ്ധാന്തത്തിന്റെ പ്രസക്തി 
    എന്തെന്നും എങ്ങനെയെന്നും പറയേണ്ടത് നിരൂപകന്റെ കടമയാണെന്നും അദ്ദേഹം ഇതിലൂടെ വിവരിക്കുന്നു.
    നിലവിലിരിക്കുന്ന നിരൂപണ സിദ്ധാന്തപ്രയോഗ മേഖലകളിൽ അയ്യപ്പപ്പണിക്കർ നടത്തിയ അന്വേഷണപഠനങ്ങളുടെ 
    ശ്രദ്ധേയമായ കണ്ടെത്തലുകളാണ് "അന്തസ്സന്നിവേശം' എന്ന നിരൂപണ ഗ്രന്ഥം. നമ്മുടെ സംവേദനശീലത്തിന്റെ ചക്രവാളം 
    നിരന്തരം വികസിപ്പിക്കുന്ന ഒരു സൈദ്ധാന്തികനെയാണ് ഈ കൃതിയിൽ കാണാനാവുന്നത്. മറ്റ് മൗലിക നിരൂപണസിദ്ധാന്തങ്ങളെപ്പോലെ 
    എഴുത്തുകാരനും വായനക്കാരനുമിടയിലുള്ള അർത്ഥരൂപീകരണത്തിന്റെയും ഭാവാർത്ഥ രസസംവേദനത്തിന്റെയും 
    സൂക്ഷ്മതലങ്ങളാണ് അന്തസ്സന്നിവേശം പഠനവിധേയമാക്കുന്നത്. 'കവിദർശനം' എന്ന ഗ്രന്ഥത്തിൽ പൂന്താനം 
    മുതൽ സമകാലികരായ കവികളെ വരെ മൂല്യവിചിന്തനത്തിനു വിധേയമാക്കിക്കൊണ്ട് പണിക്കർ സാഹിത്യനിരൂപണത്തിനും, 
    സൗന്ദര്യശാസ്ത്രത്തിനും പുതിയ മാനം നൽകി. കവിത, നാടകം, കഥ, നിരൂപണം തുടങ്ങിയ എല്ലാ സാഹിത്യ ശാഖകളേയും 
    സംബന്ധിച്ചും പണിക്കർ പഠനങ്ങൾ നടത്തിയിട്ടുണ്ട്. കുഞ്ചൻ നമ്പ്യാർ, വള്ളത്തോൾ, കുമാരനാശാൻ, ഉള്ളൂർ, ചങ്ങമ്പുഴ, 
    വൈലോപ്പിള്ളി, ഇടശ്ശേരി, ജി.ശങ്കരകുറുപ്പ്, ബാലാമണിയമ്മ തുടങ്ങിയ കവികളെയും ബഷീർ, ചന്ദുമേനോൻ, 
    സി.വി. രാമൻപിള്ള, കാരൂർ, എന്നീ കഥാകൃത്തുക്കളെക്കുറിച്ചും സി.ജെ. തോമസ്, സി.എൻ. ശ്രീകണ്ഠൻ നായർ, 
    എൻ. കൃഷ്ണപിള്ള, ജി. ശങ്കരപ്പിള്ള മുതലായവരുടെ നാടകസങ്കല്പങ്ങളേയും ജോസഫ് മുണ്ടശ്ശേരി, എം. ഗോവിന്ദൻ, 
    കുട്ടിക്കൃഷ്ണമാരാർ, ഗുപ്തൻ നായർ, അടക്കമുള്ള നിരൂപകരേയും അയ്യപ്പപ്പണിക്കർ ആസ്വാദന-വിചിന്തനത്തിന് വിധേയമാക്കുന്നു.<br/><br/>

    വില നിശ്ചയിക്കലോ, വിധി പ്രസ്താവിക്കലോ ആയിരുന്നില്ല പണിക്കരുടെ നിരൂപണ രീതി. വായനക്കാരെക്കൂടി 
    വിശ്വാസത്തിലെടുത്തു കൊണ്ടു തുറന്ന മനസ്സോടെ അന്വേഷിക്കുകയാണ് പണിക്കർ ചെയ്യുന്നത്. "നമ്മുടെ എഴുത്തുകാരുടെ 
    കൃതികളെ അവയുടെ ആന്തരികത്വത്തിന്റെ അടിസ്ഥാനത്തിൽ വിശകലനം ചെയ്യാനും സൗന്ദര്യ മൂല്യങ്ങൾ നിർധാരണം ചെയ്യാനും 
    നമുക്ക് കഴിയണം. നമ്മുടെ വിമർശകർ ഈ കാര്യത്തിൽ നമുക്കു മാർഗദർശനം തരില്ലേ? ' ഇവിടെ അദ്ദേഹം വിമർശകരെക്കുറിച്ച് - 
    മാർഗദർശനം തരുന്നില്ല എന്നുള്ള വിധി പ്രസ്താവം നടത്തുന്നില്ല എന്നത് ശ്രദ്ധേയമാണ്.<br/><br/><br/>

    <div className=' text-xl font-semibold text-tb mt-4'>നിരൂപണഗ്രന്ഥങ്ങൾ</div>
        <div className='max-w-screen-lg mx-auto text-justify mt-4'>
              1. ആഖ്യാനകല: സിദ്ധാന്തവും പ്രയോഗവിധികളും, മലയാളവിഭാഗം, കേരളസർവ്വകലാശാല, തിരുവനന്തപുരം, 1993<br/><br/>
              2. ഇന്ത്യൻ സാഹിത്യസിദ്ധാന്തം പ്രസക്തിയും സാദ്ധ്യതയും, കേരള ഭാഷാ ഇൻസ്റ്റിറ്റ്യൂട്ട്, തിരുവനന്തപുരം, 1999<br/><br/>
              3. അന്തസ്സന്നിവേശം, കേരള ഭാഷാ ഇൻസ്റ്റിറ്റ്യൂട്ട്, തിരുവനന്തപുരം, 2000<br/><br/>
              4. സി.വി. രാമൻപിള്ള, പ്രകാശനവിഭാഗം, കേരള സർവ്വകലാശാല, തിരുവനന്തപുരം, 1993<br/><br/>
              5. വൈ ഷെയ്ക്സ്പിയർ (ഇംഗ്ലീഷും മലയാളവും), ഡി.സി. ബുക്സ്, കോട്ടയം, 2000<br/><br/>
              6. കവിദർശനം, കേരള ഭാഷാ ഇൻസ്റ്റിറ്റ്യൂട്ട്, തിരുവനന്തപുരം, 2002<br/><br/>
              7. അയ്യപ്പപ്പണിക്കരുടെ രംഗകലാപഠനങ്ങൾ, എ ഡി, എം.ആർ. തമ്പാൻ, സാഹിത്യപ്രവർത്തക പ്രസാധകസംഘം, തിരുവനന്തപുരം, 2010<br/><br/>
              8. തകഴി ശിവശങ്കരപ്പിള്ള, ഡിസി ബുക്സ്
            </div>
    
    </div>



    </div>
  )
}

export default Niroopakan