import React from 'react'

const Translation = () => {
  return (
    <div className='p-5'>
<div className=''>
    <div className="relative text-center items-center justify-center">
      <img src={require(`./stroke 1.png`)} alt="example"className='w-80'  />
          <h1 className="absolute top-5 left-7 text-white font-bold text-[25px] ">TRANSLATION</h1>
    </div>
    </div>

<div className='max-w-screen-lg mx-auto text-justify mt-4 font-eng'>
    
<div className='p-9'>
<p className=' text-2xl font-semibold text-tb mt-4'> Poetry Translations:</p>
            <p className='text-lg font-bold mt-2'>1. Cuban Kavithakal Nicolas Guillen</p>
            <p>Shikha Publications, Guruvayoor 1984</p>
            <p className='text-lg font-bold mt-2'>2. Mayakoviskhyude Kavithakal</p>
            <p>Sahithyapravarthaka Sahakaranasangham, Kottayam 1988</p>
            <p className='text-lg font-bold mt-2'>3. Jeebanandhadasinte Kavithakal</p>
            <p>DC Books Kottayam 2000</p>
            <p className='text-lg font-bold mt-2'>4. Noottonnu Vachanangal</p>
            <p>H&C Publishing House, Thrissur 2009</p>
</div>
<div className='p-9'>
<p className=' text-2xl font-semibold text-tb mt-2'> Prose Translations:</p>
            <p className='text-lg font-bold mt-2'>1. Jeevanandadas Chitanandadas Gupta</p>
            <p>Sahitya Akademi, New Delhi, 1985</p>
            <p className='text-lg font-bold mt-2'>2. Guru Granth Sahib</p>
            <p>DC Books, Kottayam, 1989</p>
            <p className='text-lg font-bold mt-2'>3. Ayyappa Panikerude Vivarthanangal</p>
            <p>Poorna, Kozhikode, 1990</p>
            <p className='text-lg font-bold mt-2'>4. Poochayum Shakespearum</p>
            <p>DC Books, Kottayam, 1980</p>
            <p className='text-lg font-bold mt-2'>5. Guru Nanak</p>
            <p>Sahitya Akademi, New Delhi, 1991</p>
            <p className='text-lg font-bold mt-2'>6. Kavitha Pranayam Athmakadha-Mayakoviskhy</p>
            <p>Pappiyon, Kozhikode 2005</p>
</div>
    
    </div>




    </div>
  )
}

export default Translation