import React from 'react'

const CriticalWorks = () => {
  return (
    <div className='p-5'>
<div className=''>
    <div className="relative text-center items-center justify-center">
      <img src={require(`./stroke 1.png`)} alt="example"className='w-80'  />
          <h1 className="absolute top-6 left-8 text-white font-bold text-xl ">CRITICAL WORKS</h1>
    </div>
    </div>
    <div className='max-w-screen-lg mx-auto text-justify mt-4 font-mal'>
    
    <div className=' text-2xl font-semibold text-tb mt-4 font-eng'>Critical Works - English</div>
        <div className='max-w-screen-lg mx-auto text-justify mt-4 '>
        1. A Perspective of Malayalam Literature- Anuchitra, Madras,1990<br/>
2. A Short History of Malayalam Literature - Department of Public Relations, Govt. of Kerala, Thiruvananthapuram, 1982.<br/>
3. Indian Literature in English - Anuchitra, Madras,1989<br/>
4. Indian Narratology. New Delhi: Indira Gandhi National Centre for The Arts and Sterling Publishers, 2003<br/>
5. Interiorization: Essays on Literary Theory. Thiruvananthapuram: Icks, 2000 (By Krishna Rajan)<br/>
6. K.M. Paniker; Kerala Writers in English I-Macmillan, New Delhi,1983<br/>
7. Like a River Fed by Many a Stream: Reflections on The Confluence of Cultures, Sahithya Akademi, New Delhi, 2005<br/>
8. Manjeri Iswaran; Kerala Writers in English 5-Macmillian, New Delhi,1984<br/>
9. Robert Lowell: The Development of His Poetry (Thesis Unpublished) Indiana University, USA, 1971.<br/>
10. Spotlight on Comparative Literature-Papyrus, Calcutta,1992<br/>
11. Thakazhi Siva Sankara Pillai - University of Kerala, 1989.<br/>
12. The Mosaic of Indian Culture: Variety in Oneness (Tagore Endowment Lectures; University of Kerala 1995).<br/>
13. Why Shakespeare? Comments on Shakespeare's World and The World's Shakespeare, (in English and Malayalam), D. C. Books, Kottayam,2000<br/>
14. Vive La Difference! Visions of, And Challenges to, Cultural Plurality, The Associated Publishers, New Delhi, 2006.<br/>
            </div>
    <div className=' text-2xl font-semibold text-tb mt-4 font-eng'>Critical Works - Malayalam</div>
        <div className='max-w-screen-lg mx-auto text-justify mt-4'>
        1. Ayyappapanikarude Lekhanangal 1950-60, DC Books Kottayam 1982<br/>
2. Ayyappapanikarude Lekhanangal 1980 -90, DC Books Kottayam 1990<br/>
3. Ayyappapanikarude Lekhanangal 1990 -2005, DC Books Kottayam 2005<br/>
4. Ayyappapanikarude Avatharikakal, Current Books Thrissur 1993<br/>
5. Vyakthichithrangal: Yathradrishyagal, samskarika Prasidhikarana Vakup, Kerala Govt 2005<br/>
6. Vishvasahithyagaliloode - 1, DC Books Kottayam 2006<br/>
7. Vishvasahithyagaliloode - 2, DC Books Kottayam 2007<br/>
8. Aghyanakala: Sidhanthavum prayogavithikalum, Malayalam Dept. Kerala University Trivandrum 1993<br/>
9. Indian Sahithyasidhantham-prasakthiyum sadhyathayum, Kerala Bhasha Institute, Trivandrum 1999<br/>
10. Anthasannivesham, Kerala Bhasha Institute, Trivandrum 2000<br/>
11. C V Ramanpillai, Prakashanavibhakam, University of Kerala Trivandrum 1993<br/>
12. Why Sheakespeare, DC Books Kottayam 2000<br/>
13. Kavidarshanam, Kerala Bhasha Institute, Trivandrum 2002<br/>
14. Ayyappapanikkarude Rangakalapaadangal, AD M R Thamban, Sahithyapravarthaka Prasaadhaka sangam, Trivandrum 2010<br/>
15. Thakazhi Shivashankara pillai, DC Books<br/>
16. Ayyappa Panikkarude Sambhashanangal, Ayyappa Panicker, 2005, Olive Publications, Kozhikode<br/>
            </div>
    
    </div>

    </div>
  )
}

export default CriticalWorks