import React from 'react'

const AboutUs = () => {
  return (
    <div className='p-5'>
<div className=''>
    <div className="relative text-center items-center justify-center">
      <img src={require(`../assets/stroke 1.png`)} alt="example"className='w-80'  />
          <h1 className="absolute top-5 left-10 text-white font-bold text-2xl ">About Us</h1>
    </div>
    </div>
    <div className='max-w-screen-lg mx-auto text-justify mt-8'>
    Ayyappa Paniker Foundation is a cultural organisation based in Thiruvananthapuram, Kerala. 
    The main objective of the foundation is to continue and promote the creative and cultural 
    spirit of Ayyappa Paniker - to explore the possibilities of language, to idenitfy and nurture 
    young poets, to promote research into Malayalam and other literary cultures, and to sensitise human 
    minds to the suffering of others. Towards this end, the Ayyappa Paniker Foundation organises symposia 
    and seminars, coducts workshops and edits and publishes journals and books.
       </div>

    <div className='md:flex justify-around mt-2'>

       <div>
       <div className=''>
<div className="relative text-center items-center justify-center">
<img src={require(`../assets/stroke 1.png`)} alt="example"className='w-80'  />
  <h1 className="absolute top-5 left-10 text-white font-bold text-2xl ">Office Bearers</h1>
</div>
</div>
<div>
<p className=' text-2xl font-semibold text-tb mt-4'> President</p>
            <p className='text-lg font-bold'>TP Sreenivasan</p>
            <p>Email :tpsreenivasan@gmail.com</p>
</div>
<div>
<p className=' text-2xl font-semibold  text-tb mt-4'> Vice-President</p>
            <p className='text-lg font-bold'>Dr Desamangalam Ramakrishnan</p>
            
</div>
<div>
<p className=' text-2xl font-semibold text-tb mt-4'> Secretary</p>
            <p className='text-lg font-bold'>Priyadas G Mangalath</p>
            <p>Email :priyadasg@gmail.com</p>
</div>
<div>
<p className=' text-2xl font-semibold text-tb mt-4'> Joint Secretary</p>
            <p className='text-lg font-bold'>Amrith Lal</p>
            
</div>
<div>
<p className=' text-2xl font-semibold text-tb mt-4'> Treasurer</p>
            <p className='text-lg font-bold'>Sunil P</p>
           
</div>

       </div>


       <div>
       <div className=''>
<div className="relative text-center items-center justify-center">
<img src={require(`../assets/stroke 1.png`)} alt="example"className='w-80'  />
  <h1 className="absolute top-5 left-10 text-white font-bold text-2xl ">Executive Members</h1>
</div>
</div>
<div>
<p className=' text-lg font-semibold mt-4'> 1. Dr. K Satchidanandan<br/>
2. TP Sreenivasan<br/>
3. Priyadas G Mangalath<br/>
4. Dr MM Basheer<br/>
5. Dr Anand Kavalam<br/>
6. Amrith Lal<br/>
7. Dr. George Onakkoor<br/>
8. G Dileep Kumar<br/>
9. Dr. Sudha Gopalakrishnan<br/>
10. Meena Kumari<br/>
11. Meera Devi Paniker<br/>
12. Dr Sreenath Nair</p>
            
            
</div>

       </div>

    </div>


    <div className=''>
<div className="relative text-center items-center justify-center">
<img src={require(`../assets/stroke 1.png`)} alt="example"className='w-80'  />
  <h1 className="absolute top-5 left-10 text-white font-bold text-2xl "> Members</h1>
</div>
</div>
<div className='md:flex justify-evenly mt-2'>
    <div>
    <p className=' text-lg  mt-4'> 1. Dr. CR Prasad<br/>
2. Rajan Kailas<br/>
3. Sudeep<br/>
4. Kalyani<br/>
5. Dr. Nisha Venugopal<br/>
6. Dr. Thomas Mathew<br/>
7. Priyan C Oommen<br/>
8. Dr MR Thampan<br/>
9. Neela Padmanabhan<br/>
10. JR Kurup<br/>
11. Laila T Abraham<br/>
12. Shanthakumari<br/>
13. PR Gopinathan Nair</p>
    </div>

    <div>
    <p className=' text-lg  mt-4'> 14. Radha Madhavan<br/>
15. Chitralekha B<br/>
16. Lalitha J<br/>
17. Prema Jayakumar<br/>
18. Vasanthi Sankaranarayanan<br/>
19. Keezharoor Suku<br/>
20. Dr. MN Rajan<br/>
21. Dr. Sreedevi K Nair<br/>
22. Dr KG Balakrishnan<br/>
23. MN Karassery<br/>
24. PLSreedharan Parakode<br/>
25. Dr Chandramathi<br/>
26. Chellamma Joseph<br/>
27. Sunil Pr</p>
    </div>
</div>


    

    </div>
  )
}

export default AboutUs









