export  const links2=[
    {
        id: 1,
        link: 'കവി',
        path:'/kavi'   
    },
    {
        id: 2,
        link: 'വിവർത്തകൻ',
        path:'/vivarthakan'
    },
    {
        id: 3,
        link: 'ഗദ്യകാരൻ',
        path:'/gadhyakaran'
    },
    {
        id: 4,
        link: 'നിരൂപകൻ',
        path:'/niroopakan'
    },
    {
        id: 5,
        link: 'എഡിറ്റർ/സമാഹാരകർത്താവ്',
        path:'/editer-samaaharakarthavu'
    },
    {
        id: 6,
        link: 'അധ്യാപകൻ/ഗവേഷകൻ',
        path:'/adhyapakan-gaveshakan'
    },
    {
        id: 7,
        link: ' ഇംഗ്ലീഷ് ഗദ്യകാരൻ',
        path:'/english-gadhyakaran'
    },
    {
        id: 8,
        link: 'അണിയറ ശില്പി',
        path:'/aniyara-shilpi'
    },
    {
        id: 9,
        link: 'അയ്യപ്പപ്പണിക്കരെ കുറിച്ചുള്ള ഗ്രന്ഥങ്ങൾ',
        path:'/ayyappapanikare-kurichulla-grandhangal'
    },
] 
export  const links=[
    {
        id: 1,
        link: 'home',
        path:'/'   
    },
    {
        id: 2,
        link: 'Life',
        path:'/life'
    },
    {
        id: 3,
        link: 'Poetry',
        path:'/poetrys'
    },
    {
        id: 4,
        link: 'Translation',
        path:'/translation'
    },
    {
        id: 5,
        link: 'Edited Works',
        path:'/edited-works'
    },
    {
        id: 6,
        link: 'Critical Works',
        path:'/critical-works'
    },
   
] 

export  const ylink=[
    {
        id: 1,
        link: 'https://www.youtube.com/embed/Gx7IXd7DSKk'   
    },
    {
        id: 2,
        link: 'https://www.youtube.com/embed/SeoL3v7i4zk'
    },
    {
        id: 3,
        link: 'https://www.youtube.com/embed/qMisuN1-YmY'
    },
    {
        id: 4,
        link: 'https://www.youtube.com/embed/rzMMsOzOuLo'
    },
    {
        id: 5,
        link: 'https://www.youtube.com/embed/TB3kxjz1r1I'
    },
    {
        id: 6,
        link: 'https://www.youtube.com/embed/ZOJctbLqxyQ'
    },
]