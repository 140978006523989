import React from 'react'


const Poetry = () => {
  return (
    <div className='p-5'>
         <div className=''>
    <div className="relative text-center items-center justify-center">
      <img src={require(`./stroke 1.png`)} alt="example"className='w-80'  />
          <h1 className={`absolute top-5 left-20 text-white font-bold text-[25px] `}>POETRY</h1>
    </div>
    </div>

    <div className='max-w-screen-lg mx-auto text-justify mt-10 text-[20px]'>
    1. Ayyappapanikkarude kavithakal Part 1, DC Books Kottyam<br/><br/>
    2. Ayyappapanikkarude kavithakal Part 2, DC Books Kottayam<br/><br/>
       <br/><br/><br/>
   
       
       
       </div>
   
       


    </div>
  )
}

export default Poetry