import React from 'react'

const AyyappaPanikkareKurichullaGhrandhangal = () => {
  return (
    <div className='p-5'>
    <div className=''>
       <div className="relative text-center items-center justify-center">
         <img src={require(`./stroke 1.png`)} alt="example"className='w-80 h-20'  />
             <h1 className="absolute top-8 left-4 text-white font-bold text-[11px] ">അയ്യപ്പപ്പണിക്കരെ കുറിച്ചുള്ള ഗ്രന്ഥങ്ങൾ</h1>
       </div>
       </div>
       <div className='max-w-screen-lg mx-auto text-justify mt-10 font-mal'>
       1. കാലം മിഥ്യയാക്കാത്ത വാക്ക്, അയ്യപ്പപ്പണിക്കർ ഫൌണ്ടേഷൻ തിരുവനന്തപുരം, 2022<br/><br/>
2. അയ്യപ്പപ്പണിക്കർ ചൊൽക്കാഴ്ചകളും ചൊല്ലാക്കാഴ്ചകളും, ബി ശ്രീകുമാർ, പാപ്പാത്തി പുസ്തകങ്ങൾ തിരുവനന്തപുരം 2021<br/><br/>
3. Ayyappapaniker Forever, Folio Trivandrum 2019<br/><br/>
4. അയ്യപ്പപ്പണിക്കർ, ഡോ. ആനന്ദ് കാവാലം, ചിന്ത പബ്ലിക്കേഷൻസ്,തിരുവനന്തപുരം 2015<br/><br/>
5. നിറവേറിയ വാഗ്ദാനം അയ്യപ്പപ്പണിക്കർ എന്റെ കൊച്ചേട്ടൻ, എം ലക്ഷ്മിക്കുട്ടിയമ്മ, ഫോളിയോ തിരുവനന്തപുരം 2014<br/><br/>
6. അയ്യപ്പപ്പണിക്കരും അയ്യപ്പപ്പണിക്കരും, എം കെ സാനു, ഗ്രീൻ ബുക്സ് തൃശ്ശൂർ 2014<br/><br/>
7. അയ്യപ്പപ്പണിക്കർ നിഷേധത്തിന്റെ ചാരുരൂപം, പ്രൊഫ. എം.കെ. സാനു, ഡി.സി. ബുക്സ് കോട്ടയം. 2014<br/><br/>
8. അയ്യപ്പപ്പണിക്കർ, ഡോ. എം.ആർ. തമ്പാൻ, കേരള ഭാഷാ ഇൻസ്റ്റിറ്റ്യൂട്ട്, തിരുവനന്തപുരം 2013<br/><br/>
9. Every Thing is Past Tense, Rati Saxena, Kritya, Trivandrum 2012<br/><br/>
10. അയ്യപ്പപ്പണിക്കരുടെ നർമ്മ സംഭാഷണങ്ങളും നർമ്മകവിതകളും, ഡി സി ബുക്സ് -2011<br/><br/>
11. അയ്യപ്പപ്പണിക്കർ ജീവിതരേഖ, അയ്യപ്പപ്പണിക്കർ ഫൌണ്ടേഷൻ തിരുവനന്തപുരം, ഡിസി ബുക്സ് കോട്ടയം 2007<br/><br/>
12. അയ്യപ്പപ്പണിക്കർ സ്മരണിക - കേരളം കവിത 2007<br/><br/>
13. കറുത്ത ചിരിയുടെ കവി, ഡോ സാമുവൽ കാട്ടുകല്ലിൽ, കേരള ഭാഷാ ഇൻസ്റ്റിറ്റ്യൂട്ട്, തിരുവനന്തപുരം 1999<br/><br/>
14. അയ്യപ്പപ്പണിക്കർ വ്യക്തിയും കവിയും, എഡിറ്റർ - ഡോ ജോർജ് ഓണക്കൂർ, ഡിസി ബുക്സ് കോട്ടയം 1990 <br/><br/>
       <br/><br/><br/>
   
       
       
       </div>
   
       </div>
  )
}

export default AyyappaPanikkareKurichullaGhrandhangal