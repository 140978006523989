import React from 'react'

const AniyaraShilpi = () => {
  return (
    <div className='p-5'>
    <div className=''>
       <div className="relative text-center items-center justify-center">
         <img src={require(`./stroke 1.png`)} alt="example"className='w-80'  />
             <h1 className="absolute top-7 left-4 text-white font-bold text-xl ">അണിയറ ശില്പി</h1>
       </div>
       </div>
       <div className='max-w-screen-lg mx-auto text-justify  mt-8 font-mal'>
       സാഹിത്യത്തിൽ മാത്രമല്ല പാരമ്പര്യകലകളിലും നാടകത്തിലും, ചലച്ചിത്രത്തിലും, ചിത്രകലയിലും ഉൾപ്പടെ കലയുടെ എല്ലാ 
       മേഖലകളിലുമുള്ള നവീകരണമാണ് ആധുനികത എന്ന് അയ്യപ്പപ്പണിക്കർ വിശ്വസിച്ചിരുന്നു. കഥകളി, കൂടിയാട്ടം, 
       നങ്ങ്യാങ്യാർകൂത്ത്, ചാക്യാർകൂത്ത്, നാടോടിക്കലകൾ തുടങ്ങിയവയ്ക്ക് പുതു ആവിഷ്കാര സാധ്യതകൾ കണ്ടെത്താനുള്ള ശ്രമങ്ങൾക്ക് പ്രേരകശക്തിയായി അയ്യപ്പപ്പണിക്കർ നിലകൊണ്ടു. മാർഗി കഥകളി വിദ്യാലയത്തിന്റെ സ്ഥാപനത്തിലും നടത്തിപ്പിലും 
       അപ്പുക്കുട്ടൻ നായരെ ഉത്സാഹിപ്പിച്ച അയ്യപ്പപ്പണിക്കർ, ജി. ശങ്കരപ്പിള്ളയുടെ നാടകക്കളരിക്കും, കാവാലത്തിന്റെ തനതു 
       നാടകവേദിക്കും, കൊച്ചുനാരായണന്റെ രംഗ പ്രഭാതിനും ഉന്മേഷം നൽകിയ പങ്കാളിയായി. കാവാലം നാരായണപണിക്കർ, 
       ജി. ശങ്കരപ്പിള്ള, നരേന്ദ്രപ്രസാദ് തുടങ്ങിയ നാടകകൃത്തുക്കളും കൊടിയേറ്റം ഗോപി, ഭാരത് മുരളി, നെടുമുടി വേണു, 
       ജഗന്നാഥൻ, ഗോപകുമാർ, എം.കെ. ഗോപാലകൃഷ്ണൻ തുടങ്ങിയ നടന്മാരും പണിക്കരിൽ നിന്നും പ്രചോദനം ഉൾക്കൊണ്ട് 
       നാടകരംഗത്ത് ആധുനികതയുടെ സ്ഥാപനത്തിന് കാരണഭൂതരായി. ലിറ്റിൽ മാഗസിനുകളുടെ പ്രകാശനത്തോടനുബന്ധിച്ചും, 
       സാഹിത്യസമ്മേളനങ്ങളോടനുബന്ധിച്ചും ചൊൽക്കാഴ്ചകളും, ഭാവുകത്വമാറ്റം വിളിച്ചോതുന്ന നാടകങ്ങളും അവതരിപ്പിക്കപ്പെട്ടിരുന്നു. 
       ചിത്രകാരന്മാരായ എം.വി. ദേവൻ, കാനായി കുഞ്ഞിരാമൻ, പാരീസ് വിശ്വനാഥൻ, ബി.ഡി. ദത്തൻ, 
       തുടങ്ങിയവർ പണിക്കരുടെ സ്വാധീനവലയത്തിൽ ഉൾപ്പെട്ടവരാണ്. മലയാള ചലച്ചിത്രരംഗത്ത് നവഭാവുകത്വ 
       സൃഷ്ടാക്കളും പ്രചാരകരുമെന്ന നിലയ്ക്ക് മൂന്നു സംവിധായകരെ - അരവിന്ദൻ, അടൂർ ഗോപാലകൃഷ്ണൻ, 
       ജോൺ എബ്രഹാം - അയ്യപ്പപ്പണിക്കർ പരിചയപ്പെടുത്തുന്നുണ്ട്. ഇവരുടെ സിനിമകളെ വസ്തുനിഷ്ഠമായി വിലയിരുത്തുകയും 
       സർഗ്ഗശേഷിയെ ആഴത്തിൽ മൂല്യവിചാരത്തിന് വിധേയമാക്കുകയും ചെയ്യുന്ന ലേഖനങ്ങൾ അദ്ദേഹം രചിച്ചിട്ടുണ്ട്.<br/><br/><br/>
   
       
       
       </div>
   
       </div>
  )
}

export default AniyaraShilpi