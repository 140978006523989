import React from 'react'

const AdhyapakaGaveshakan = () => {
  return (
    <div className='p-5'>
 <div className=''>
    <div className="relative text-center items-center justify-center">
      <img src={require(`./stroke 1.png`)} alt="example"className='w-80'  />
          <h1 className="absolute top-7 left-4 text-white font-bold text-[15px] ">അധ്യാപകൻ/ഗവേഷകൻ</h1>
    </div>
    </div>
    <div className='max-w-screen-lg mx-auto text-justify mt-4 font-mal'>
    തന്റെ വിദ്യാർത്ഥികളിൽ മായാത്ത മുദ്ര പതിപ്പിച്ച ഒരു വിശിഷ്ട അധ്യാപകനായിരുന്നു അയ്യപ്പപ്പണിക്കർ. 1951 മുതൽ 1965 വരെ കോട്ടയം സി.എം.എസ്. കോളേജ്, തിരുവനന്തപുരം എം.ജി. കോളേജ്, 
    തിരുവനന്തപുരം യൂണിവേഴ്സിറ്റി കോളേജ് എന്നിവിടങ്ങളിലും അതിനുശേഷം കേരള സർവകലാശാല 
    ഇംഗ്ലീഷ് വിഭാഗ(ഇൻസ്റ്റിറ്റ്യൂട്ട് ഓഫ് ഇംഗ്ലീഷ്)ത്തിലു മായി നാല്പത് വർഷത്തിലേറെക്കാലം ഇംഗ്ലീഷ് 
    അധ്യാപനവൃത്തിയിൽ ഏർപ്പെട്ട പണിക്കർ കേരളത്തിന്റെ അക്കാദമിക സമൂഹത്തിൽ ഒരു വലിയ 
    സ്വാധീനശക്തിയായിരുന്നു.<br/><br/>
    ക്ലാസ് മുറിയിലെ അധ്യാപകൻ മാത്രമായിരുന്നില്ല അയ്യപ്പപ്പണിക്കർ. വിദ്യാർത്ഥികളുടെ ജീവിതയാത്രയിൽ 
    മാർഗദർശി കൂടിയായിരുന്നു. സ്വയം ഒരു ഗവേഷകനായിരുന്ന അദ്ദേഹം അനേകം ഗവേഷണ വിദ്യാർത്ഥികൾക്ക് 
    ഗൈഡായും പ്രവർത്തിച്ചിട്ടുണ്ട്. അദ്ദേഹത്തിന്റെ പ്രഭാഷണങ്ങൾ കേട്ട് ആകൃഷ്ടരായവരും, കവിയരങ്ങുകളിൽ 
    പങ്കെടുത്തവരും, സാഹിത്യക്യാമ്പുകളിലും വർക്ക് ഷോപ്പുകളിലും മറ്റും ഭാഗവാക്കായവരും, അദ്ദേഹം നടത്തിയിരുന്ന 
    "കേരളകവിത' 'സംക്രമണംകവിതവേദി' തുടങ്ങിയവയിലൂടെ സാഹിത്യലോകത്തിലേക്കു ആനയിക്കപ്പെട്ടവരും അദ്ദേഹത്തെ 
    തങ്ങളുടെ ഗുരുവായി കാണുന്നു. മൂന്ന് തലമുറകളുടെ ചിന്തയും ഭാവനയും ഉദ്ദീപിപ്പിച്ചു സ്വാധീനിച്ച ഗുരുവാണ് അയ്യപ്പപ്പണിക്കർ. 
    "എങ്ങനെയല്ല ജീവിക്കേണ്ടതെന്ന് ജീവിതകാലം മുഴുവൻ ക്ഷമാപൂർവ്വം പഠിച്ച ആ അധ്യാപകൻ പക്ഷെ, 'പഠിക്കാനാവുന്നത് പഠിപ്പിക്കാൻ 
    ആവില്ല' എന്ന് തിരിച്ചറിഞ്ഞ ജ്ഞാനി ആയിരുന്നു.<br/><br/>
    അയ്യപ്പപ്പണിക്കരുടെ ശിഷ്യർ അദ്ദേഹത്തെ ഓർമിക്കുന്ന Ayyappapaniker Forever (Folio, Trivandrum) എന്ന ഗ്രന്ഥം 
    അയ്യപ്പപ്പണിക്കർ എന്ന ഗുരുവിന് സമർപ്പിക്കപ്പെട്ട സത്യവാങ്മൂലമാണ്.<br/><br/>
   <br/><br/>
   
    <div>


        <div className='max-w-screen-lg mx-auto text-justify '>
        "Today, with 23 years of teaching experience behind me, not a single day passes when I don't touch his feet with my 
    heart- a man who made me whatever I am today. My students know my guru well enough, as I recount my precious, priceless 
    moments with him. Whatever you think is positive or excellent in me as a teacher, I owe completely to him. whatever 
    is negative or inferior, is mine alone".
    <p className=' text-xl font-semibold text-tb mt-2'>Dr. Dhanya Menon</p>
        <p className='max-w-screen-lg mx-auto text-justify '>
        (Institute of English, 1989-1990)
            </p>
            </div>
    </div>
            
    
            <div className='mt-10'>
            <div className='max-w-screen-lg mx-auto text-justify mt-5'>
        "Yes, he was not by any standard of convention a model teacher, but he was constantly unconventionally unique in helping 
        his students find their way out of the academic labyrinth, helping them erect meaningful worlds of their own".
        <p className=' text-xl font-semibold text-tb mt-2'>Dr. Chitra Panikker</p>
        <p className='max-w-screen-lg mx-auto text-justify '>
        (Institute of English, 1985-1990)
            </p>
            </div>
            </div>
           
    
    </div>




    </div>
  )
}

export default AdhyapakaGaveshakan