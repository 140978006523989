import React from 'react'

const Gadhyakaran = () => {
  return (
    <div className='p-5'>
        
        <div className=''>
    <div className="relative text-center items-center justify-center">
      <img src={require(`./stroke 1.png`)} alt="example"className='w-80'  />
          <h1 className="absolute top-5 left-10 text-white font-bold text-2xl ">ഗദ്യകാരൻ</h1>
    </div>
    </div>

    <div className='max-w-screen-lg mx-auto text-justify mt-4 font-mal'>
             കാവ്യരചനകളോളം തന്നെ വിപുലവും വൈവിധ്യമേറിയതുമാണ് അയ്യപ്പപ്പണിക്കരുടെ ഗദ്യ രചനകളും.
             ലേഖനങ്ങളുടെ മൂന്നു സമാഹാരങ്ങൾ ഡി.സി. ബുക്സ് പ്രസിദ്ധീകരിച്ചിട്ടുണ്ട്. ലഘു ജീവചരിത്രങ്ങൾ, യാത്രാനുഭവങ്ങൾ, 
             അവതാരികകൾ, സംഭാഷണങ്ങൾ, രംഗകലാപഠനങ്ങൾ എന്നിങ്ങനെ വിവിധ വിഷയങ്ങളെ ആസ്പദമാക്കിയുള്ള 
             ഉപന്യാസങ്ങൾ പുസ്തകങ്ങളായി പ്രസിദ്ധീകരിക്കപ്പെട്ടിട്ടുണ്ട്. അദ്ദേഹം എഡിറ്റ് ചെയ്ത പുസ്തങ്ങളുടെ മുഖവരകൾ, 
             ഡി.സി. ബുക്സ് പ്രസിദ്ധീകരിച്ച വിശ്വസാഹിത്യമാലയിലെ 120 കൃതികൾക്ക് എഴുതിയ ആമുഖപഠനങ്ങൾ തുടങ്ങിയവ 
             സാഹിത്യ പ്രേമികളായ സാധാരണക്കാർക്കു വളരെ പ്രയോജനം ചെയ്തു. എഴുത്തുകാരെയും കൃതികളെയും വിലയിരുത്തുന്ന 
             വ്യക്തിചിത്രങ്ങളിലും വിമർശനാത്മക ലേഖനങ്ങളിലും യാത്രദൃശ്യങ്ങളിലും പണിക്കരുടെ പലവക ലേഖനങ്ങളിലും സൂക്ഷ്മദർശിയായ 
             ഒരു പ്രവാചകന്റെ ശബ്ദം നമുക്ക് കേൾക്കാനാകും.<br/><br/>

             ഗഹനമായ വിഷയങ്ങളല്ല, സാധാരണ വിഷയങ്ങൾ ലളിതമായി പ്രതിപാദിക്കുന്ന കുറിപ്പുകളിലൂടെയും ലേഖനങ്ങളിലൂടെയും 
             പണിക്കർ അഗാധമായ ഉൾക്കാഴ്ചയാണ് വായനക്കാർക്ക് സമ്മാനിക്കുന്നത്. ഉപന്യാസകാരൻ എന്ന നിലയിൽ അയ്യപ്പപ്പണിക്കർ 
             വാചാലനല്ല. കുറഞ്ഞ വാക്കുകളിൽ കൂടുതൽ ആശയങ്ങൾ, ആഴത്തിലുള്ള അറിവുകൾ അദ്ദേഹം പകർന്നുതരുന്നു. 
             സാംസ്കാരികരംഗത്ത് പ്രവർത്തിക്കുന്ന ഒരു ധിഷണിശാലിയെന്ന വിധത്തിലും അതിലേറെ സൂക്ഷ്മദൃക്കായ ഒരു വായനക്കാരൻ 
             എന്ന രീതിയിലും നടത്തുന്ന ഇടപെടലുകളാണ് അയ്യപ്പപ്പണിക്കരുടെ ഗദ്യരചനകൾ.<br/><br/><br/>

             1. അയ്യപ്പപ്പണിക്കരുടെ ലേഖനങ്ങൾ 1950-60, ഡി സി ബുക്സ്, കോട്ടയം, 1982<br/><br/>
             2. അയ്യപ്പപ്പണിക്കരുടെ ലേഖനങ്ങൾ 1980-1990, ഡി സി ബുക്സ്, 1990<br/><br/>
             3. അയ്യപ്പപ്പണിക്കരുടെ ലേഖനങ്ങൾ 1990-2005, ഡി സി ബുക്സ്, കോട്ടയം, 2005<br/><br/>
             4. അയ്യപ്പപ്പണിക്കരുടെ അവതാരികകൾ, കറന്റ് ബുക്സ്, തൃശൂർ, 1993<br/><br/>
             5. വ്യയക്തിചിത്രങ്ങൾ; യാത്രാദൃശ്യങ്ങൾ, സാംസ്കാരിക പ്രസിദ്ധീകരണവകുപ്പ്, കേരളസർക്കാർ, 2005<br/><br/>
             6. വിശ്വസാഹിത്യങ്ങളിലൂടെ-1, ഡിസി ബുക്സ്, കോട്ടയം, 2006<br/><br/>
             7. വിശ്വസാഹിത്യങ്ങളിലൂടെ-2, ഡിസി ബുക്സ്, കോട്ടയം, 2006<br/><br/>
             8. അയ്യപ്പപ്പണിക്കരുടെ സംഭാഷണങ്ങൾ, ഒലിവ് പബ്ലിക്കേഷൻസ്, കോഴിക്കോട്, 2005<br/><br/>


    </div>




    </div>
  )
}

export default Gadhyakaran